<template>
    <div :style="cssProps">
        <div class="rounded-3xl p-8 round-block sm:rounded-xl h-full w-full flex flex-col">
            <div class="flex justify-between">
                <img :src="icon" alt="icon">
                <img v-if="icon2" :src="icon2" alt="icon2">
            </div>
            <h3 class="mt-10 mb-4">{{ title }}</h3>
            <slot/>
        </div>
    </div>
</template>
<script>

export default {
    name: 'round-block-icon',
    props: {
        background: {default: '#ffffff'},
        textColor: {default: '#4c4c4c'},
        title: {},
        icon: {},
        icon2: {},
    },
    data: () => ({}),
    computed: {
        cssProps() {
            return {
                '--text-bg-color': this.background,
                '--text-color': this.textColor,
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.round-block {
    background-color: var(--text-bg-color);
    color: var(--text-color);
}
</style>