<template>
    <site-header :phone="phone"></site-header>
    <div class="bg-[#f1eee8]">
        <div class="container">
            <div class="text-center text-xs pt-12 text-[#716e64]">Партнерский портал</div>
            <h1 class="mt-8 w-4/5 mx-auto">Зарабатывайте с партнерской программой Т-Партнеры</h1>
            <div class="mt-6 text-center">Регистрируйтесь на платформе, выбирайте офферы, продвигайте их среди вашей аудитории и получайте вознаграждение</div>

            <yellow-button class="mt-10 block mx-auto" @click="gotoUrl('https://partners.tbank.ru/signup/')">Зарегистрироваться</yellow-button>

            <img class="mt-16" src="@/assets/images/top-blur.webp" alt="top">

            <div class="mt-16 grid grid-cols-2 gap-12 sm:grid-cols-1 sm:gap-4">
                <round-block-icon :icon="require('@/assets/images/i1-1.svg')" title="Более 6000 партнеров">
                    Участники программы Т-Партнеры и партнеров
                </round-block-icon>
                <round-block-icon :icon="require('@/assets/images/i1-2.svg')" title="Более 300 офферов">
                    Рекламные предложения для вашей аудитории
                </round-block-icon>
            </div>

            <h2 class="mt-24">Как работает партнерская программа</h2>
            <div class="mt-16 grid grid-cols-3 gap-12 sm:grid-cols-1 sm:gap-4">
                <custom-list class="mt-8" :items="[
                    'Вы заполняете заявку на этом портале и получаете доступ в личный кабинет',
                    'Продвигаете офферы партнерки',
                    'Мы выплачиваем вознаграждение за каждое целевое действие, указанное в оффере: например если клиент оформил карту или кредит'
                ]"/>
                <img class="col-span-2 bg-[#e4d7c1] rounded-3xl" src="@/assets/images/howto.webp" alt="howto">
            </div>

            <h2 class="mt-20">С партнерской программой <nobr>Т-Партнеры</nobr><br>ваш доход не ограничен</h2>
            <div class="mt-8 text-center">Чем больше офферов вы продвинете, тем выше может быть ваш заработок на партнерке</div>
            <div class="mt-16 grid grid-cols-2 gap-12 sm:grid-cols-1 sm:gap-4">
                <round-block background="#e4d7c1">
                    <h3>Регулярные выплаты</h3>
                    <span class="mt-3">Раз в месяц проводим сверку. Статистика по заработку на партнерской программе — в личном кабинете</span>
                </round-block>
                <round-block background="#e4d7c1">
                    <h3>Без комиссий посредников</h3>
                    <span class="mt-3">Вы подключаетесь к рекламным предложениям Т‑Банка и партнеров и получаете выплаты напрямую</span>
                </round-block>
            </div>

            <h2 class="mt-20">К партнерской сети могут подключиться <br>ИП, компании и физлица</h2>
            <div class="mt-12 grid grid-cols-3 gap-12 sm:grid-cols-1 sm:gap-4">
                <round-block-icon :icon="require('@/assets/images/i2-1.svg')" title="Агенты">
                    <div class="flex flex-col h-full">
                        <span class="grow">
                            Те, кто лично рекомендует сервисы своим клиентам, друзьям и знакомым
                        </span>
                        <a class="bg-[#f4f5f8] text-[#4186f7] px-4 py-3 mt-6 rounded-xl w-fit" href="https://partners.tbank.ru/signup/">Стать партнером</a>
                    </div>
                </round-block-icon>
                <round-block-icon :icon="require('@/assets/images/i2-2.svg')" title="Веб-мастера">
                    <div class="flex flex-col h-full">
                        <span>
                            Владельцы сайтов и мобильных приложений, специалисты по привлечению трафика
                        </span>
                        <a class="bg-[#f4f5f8] text-[#4186f7] px-4 py-3 mt-6 rounded-xl w-fit" href="https://partners.tbank.ru/signup/">Стать партнером</a>
                    </div>
                </round-block-icon>
                <round-block-icon :icon="require('@/assets/images/i2-3.svg')" title="Блогеры">
                    <div class="flex flex-col h-full">
                        <span>
                            Авторы контента на YouTube, в Телеграме, ВКонтакте и на других площадках
                        </span>
                        <a class="bg-[#f4f5f8] text-[#4186f7] px-4 py-3 mt-6 rounded-xl w-fit" href="https://partners.tbank.ru/signup/">Стать партнером</a>
                    </div>
                </round-block-icon>
            </div>

            <h2 class="mt-20 sm:hidden">Выбирайте офферы, которые подходят вашей&nbsp;аудитории</h2>
            <div class="mt-4 text-center sm:hidden">Все рекламные предложения партнерской сети доступны в личном кабинете</div>
            <div class="mt-12 grid grid-cols-2 gap-12 sm:grid-cols-1 sm:gap-4 sm:hidden">
                <round-block-nopr background="#e4d7c1">
                    <div class="flex sm:flex-col">
                        <div class="grow pt-8 pl-8">
                            <h3 class="mb-3">Кредитная карта «Платинум»</h3>
                            <span>Кредитный лимит до 1 000 000 ₽. Беспроцентный период до&nbsp;55&nbsp;дней за любые покупки по&nbsp;карте</span>
                        </div>
                        <img class="w-6/12 pl-2" src="@/assets/images/platinum.avif" alt="platinum">
                    </div>
                </round-block-nopr>
                <round-block-nopr background="#e4d7c1">
                    <div class="flex sm:flex-col">
                        <div class="grow pt-8 pl-8">
                            <h3 class="mb-3">Дебетовая карта Black</h3>
                            <span>Главная дебетовая карта Т‑Банка с кэшбэком до 30% рублями по&nbsp;спецпредложениям и&nbsp;процентом на&nbsp;остаток</span>
                        </div>
                        <img class="w-6/12 pl-2" src="@/assets/images/black.avif" alt="black">
                    </div>
                </round-block-nopr>
                <round-block-nopr background="#e4d7c1" class="col-span-2">
                    <div class="flex sm:flex-col">
                        <div class="grow pt-8 pl-8">
                            <h3 class="mb-3">Т‑Инвестиции</h3>
                            <span>Открытие брокерского счета — 0 ₽. Покупка и продажа фондов Т‑Банка&nbsp;без&nbsp;комиссии</span>
                        </div>
                        <img class="w-6/12 pl-2" src="@/assets/images/invest.webp" alt="invest">
                    </div>
                </round-block-nopr>
            </div>

            <h2 class="mt-20">Личный кабинет для прозрачной работы</h2>
            <div class="mt-12 grid grid-cols-3 gap-12 sm:grid-cols-1 sm:gap-4">
                <img class="col-span-2 bg-[#e4d7c1] rounded-3xl" src="@/assets/images/lk.webp" alt="lk">
                <div class="mt-8">
                    <h3>Подробная статистика</h3>
                    <div class="mt-3">Отслеживайте конверсии в заявку, средний доход на клик, статусы заявок. Данные обновляются в режиме реального времени</div>
                    <h3 class="mt-10">Работа в привычном интерфейсе</h3>
                    <div class="mt-3">Если вам удобнее получать статистику по конверсиям и заработку в своей системе, можете настроить интеграцию по Post Back</div>
                    <h3 class="mt-10">Помощь персонального менеджера</h3>
                    <div class="mt-3">Менеджер обучит работе с личным кабинетом партнерки и будет на связи по любым вопросам&nbsp;—&nbsp;он быстро отвечает в&nbsp;специальном&nbsp;чате</div>
                </div>
            </div>

            <h2 class="mt-24">Регистрируйтесь в партнерской программе</h2>
            <div class="mt-16 grid grid-cols-3 gap-12 sm:grid-cols-1 sm:gap-4">
                <step-block icon="1" icon-background="#b58b60">
                    Заполните заявку на этом портале и получите доступ в личный кабинет
                </step-block>
                <step-block icon="2" icon-background="#b58b60">
                    Выберите подходящие офферы из&nbsp;каталога
                </step-block>
                <step-block icon="3" icon-background="#b58b60">
                    Продвигайте офферы и получайте доход от партнерской сети
                </step-block>
            </div>
            <yellow-button class="mt-12 block mx-auto" @click="gotoUrl('https://partners.tbank.ru/signup/')">Заполнить заявку</yellow-button>

            <h2 class="mt-24">Частые вопросы</h2>
            <div class="mt-12 py-4 px-24 rounded-3xl bg-white sm:mx-0 sm:px-4">
                <accordion-list :open-multiple-items="true">
                    <accordion-item v-for="item in faq" :key="item.title" class="py-1 border-b last:border-b-0 items-start justify-between" :expanded="false">
                        <template #summary>
                            <h3 class="py-1 text-[#333333] hover:text-[#9d9d9d] text-2xl sm:text-[17px] sm:pr-2 sm:leading-snug sm:font-bold">{{ item.title }}</h3>
                        </template>
                        <div class="pl-4">
                            <div v-html="item.text"></div>
                        </div>
                    </accordion-item>
                </accordion-list>
            </div>

            <div class="mt-32">&nbsp;</div>
        </div>
    </div>
    <site-footer
        :bank-links="[]"
        :product-links="[
            {text: 'Правила использования', link: require('@/assets/docs/rules.pdf')},
            {text: 'Правила обработки персональных данных', link: require('@/assets/docs/pd.pdf')},
            {text: 'info-partners@tinkoff.ru', link: 'mailto:info-partners@tinkoff.ru'}
        ]"
        disclaimer="ООО «Т‑Партнерс» не гарантирует получение доходности при участии в партнерской сети. Клиент самостоятельно принимает решения по работе с офферами и несет ответственность за полученный результат.<br>Не допускается продвижение офферов на ресурсах, признанных запрещенными в Российской Федерации, в том числе на ресурсах компаний, признанных экстремистскими в Российской Федерации, и на ресурсах лиц, признанных иностранными агентами.<br>
Оффер — рекламное предложение. API — программный интерфейс приложения. Post Back — обратная передача данных. EPC (earn per click) — доходность на клик. EPL (earn per lead) — доходность на лид"
        copyright="ООО «Т‑Партнерс»"
    />
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header-v2";
import SiteFooter from "@/components/site-footer-v2";
import YellowButton from "@/components/yellow-button.vue";
import RoundBlockIcon from "@/components/round-block-icon.vue";
import CustomList from "@/components/custom-list.vue";
import RoundBlock from "@/components/round-block.vue";
import RoundBlockNopr from "@/components/round-block-nopr.vue";
import StepBlock from "@/components/step-block.vue";
import {AccordionItem, AccordionList} from "vue3-rich-accordion";

export default {
    name: 'App',
    components: {AccordionItem, AccordionList, StepBlock, RoundBlock, CustomList, RoundBlockIcon, YellowButton, SiteHeader, SiteFooter, RoundBlockNopr},

    data: () => ({
        urlParams: '',
        faq: [
            {
                title: 'Что такое партнерская программа?',
                text: 'Это форма сотрудничества: партнеры привлекают клиентов рекламодателю, а он платит вознаграждение за целевые действия этих клиентов. С партнерской программой Т-Партнеры вы можете продвигать сервисы банка и других компаний.'
            },
            {
                title: 'Что такое оффер?',
                text: 'Оффер — предложение рекламодателя, которое продвигают партнеры. В оффере указана информация о сервисе или услуге, срок предложения, целевое действие и размер вознаграждения.'
            },
            {
                title: 'Какие есть виды партнерских программ и модели оплаты?',
                text: 'Партнерки различаются сферами: например, есть партнерские программы в сфере электронной торговли, финансовые, туристические и другие.<br>' +
                    '<br>' +
                    'В партнерских программах есть разные способы расчета и выплаты вознаграждения:<br><br>' +
                    '—&emsp;CPA (cost per action) — плата за действие. Вознаграждение платят за целевое действие, которое совершит пользователь по ссылке партнера: например, оставит заявку на консультацию или подключит сервис. Целевое действие указано в оффере.<br><br>' +
                    '—&emsp;CPS (cost per sale) — плата за продажу. Вознаграждение выплачивают, только если пользователь купил товар или услугу. Это может быть фиксированная сумма или процент с продажи.<br><br>' +
                    '—&emsp;CPC (cost per click) — плата за клик. Участникам партнерской программы платят за каждый клик по их ссылке. Дальнейшие действия пользователей не влияют на оплату.<br><br>' +
                    '—&emsp;CPV (cost per view) — плата за показ. Партнер получает гонорар за просмотры его контента — например, видео или баннера.<br><br>' +
                    '—&emsp;CPI (cost per install) — плата за установку. Партнерское вознаграждение платят за скачивание приложения или программы по реферальной ссылке.'
            },
            {
                title: 'Чем партнерская программа отличается от реферальной?',
                text: 'К партнерской программе обычно подключаются те, у кого есть опыт в онлайн-привлечении: например, блогеры, веб-мастера, арбитражники. Чтобы участвовать в программе, партнер получает доступ в личный кабинет.<br>' +
                    '<br>' +
                    'Для участия в реферальной программе не нужен специальный опыт. Как правило, участники предлагают сервисы друзьям и знакомым. Например, клиент может зайти в личный кабинет банка, скопировать ссылку на открытие счета и прислать ее другу. Если друг откроет счет, клиент получит вознаграждение.'
            },
            {
                title: 'Как стать партнером?',
                text: 'Для этого нужно:<br>' +
                    '<br>' +
                    '1.&emsp;Заполнить форму онлайн — на этой странице.<br>' +
                    '2.&emsp;Изучить правила работы с партнерской программой и принять <a class="text-blue-600" href="https://partners.cdn-tinkoff.ru/media/files/e/R/eRTjbX1k0hHr1lcHH9GppQoQrZSgu5Zm79Ro5YL4.pdf">оферту</a>.<br>' +
                    '3.&emsp;Выбрать подходящие офферы в личном кабинете и продвигать их.'
            },
            {
                title: 'Кто может стать партнером?',
                text: 'К партнерской сети могут подключиться все, кто хочет продвигать офферы Т‑Банка и других рекламодателей.<br>' +
                    '<br>' +
                    'На кого рассчитана программа:<br>' +
                    '<br>' +
                    '—&emsp;на веб-мастеров;<br>' +
                    '—&emsp;блогеров;<br>' +
                    '—&emsp;арбитражников;<br>' +
                    '—&emsp;агентов — тех, кто лично рекламирует сервисы своим клиентам.<br>' +
                    'Для подключения не обязательно иметь опыт работы с партнерскими программами — новички тоже могут участвовать.'
            },
            {
                title: 'Нужно ли быть ИП или ООО для работы в партнерке?',
                text: 'Это не обязательно, мы работаем не только с бизнесом. К партнерской сети Т-Партнеры могут подключиться физлица, ИП и юрлица на любой системе налогообложения, самозанятые.'
            },
            {
                title: 'Как формируется вознаграждение партнеров?',
                text: 'На вознаграждение влияет:<br>' +
                    '<br>' +
                    '—&emsp;количество клиентов, которые совершили целевые действия, — чем больше, тем выше будет ваш доход;<br>' +
                    '—&emsp;сервисы, которые вы продвигаете. Выплаты за оффер различаются: например, за оформление кредита и открытие карты могут быть разные вознаграждения.<br>' +
                    'Мы рассчитываем сумму за календарный месяц. Отчет о вознаграждениях можно посмотреть в личном кабинете партнера. В нем также можно смотреть прошлую статистику по выплатам за объем трафика.'
            },
            {
                title: 'Сколько можно зарабатывать на партнерской программе?',
                text: 'Мы не ограничиваем партнеров в заработке. Доход зависит от того, сколько клиентов совершили целевые действия по вашим ссылкам.<br>' +
                    '<br>' +
                    'Вот несколько способов увеличить заработок:<br>' +
                    '<br>' +
                    '—&emsp;изучить аудиторию и продвигать сервисы, которые ей больше подходят;<br>' +
                    '—&emsp;использовать несколько каналов привлечения;<br>' +
                    '—&emsp;выбирать офферы с более высоким вознаграждением;<br>' +
                    '—&emsp;ориентироваться на средние показатели — доходность на клик, EPC, и доходность на лид, EPL. Эти показатели указаны в оффере;<br>' +
                    '—&emsp;рекламировать сервисы, которые больше соответствуют тематике вашей площадки;<br>' +
                    '—&emsp;продвигать типы офферов, для которых у вас уже настроена рекламная кампания.'
            },
            {
                title: 'Какими способами можно продвигать офферы?',
                text: 'Вот несколько способов привлечь трафик:<br>' +
                    '<br>' +
                    '—&emsp;на своем сайте — в статье или на баннере;<br>' +
                    '—&emsp;в блоге;<br>' +
                    '—&emsp;в социальных сетях;<br>' +
                    '—&emsp;на тематических форумах;<br>' +
                    '—&emsp;в email-рассылках;<br>' +
                    '—&emsp;с помощью контекстной рекламы;<br>' +
                    '—&emsp;через звонки по базе клиентов;<br>' +
                    '—&emsp;по QR-коду со ссылкой на оффер, если работаете с клиентами офлайн.'
            },
            {
                title: 'Какие финансовые сервисы Т‑Банка можно продвигать в партнерской программе?',
                text: 'В партнерской сети есть самые популярные предложения банка: дебетовые и кредитные карты, кредиты для бизнеса и физлиц, расчетный счет для ИП и компаний. Все офферы можно посмотреть в каталоге — он доступен в личном кабинете партнера.'
            },
            {
                title: 'Как работают партнерские ссылки?',
                text: 'В каждой партнерской ссылке есть уникальный идентификатор — он позволяет отследить, от какого партнера пришел клиент, и рассчитать вознаграждение.<br>' +
                    '<br>' +
                    'Ссылку можно скопировать из личного кабинета партнерской программы. Важно использовать именно партнерские ссылки: если скопировать ссылку из адресной строки, мы не сможем рассчитать и начислить вознаграждение. Также в личном кабинете можно отслеживать статистику по целевым действиям клиентов.'
            },
            {
                title: 'Нужно ли подписывать договор?',
                text: 'Партнеру нужно присоединиться к оферте. Бумажный договор подписывать не требуется.'
            },
            {
                title: 'Какие еще термины используются в партнерском маркетинге?',
                text: 'Аффилейт-маркетинг, или партнерский маркетинг, — продвижение товаров и услуг с помощью посредников.<br>' +
                    '<br>' +
                    'Рекламодатель — тот, кто предлагает свои товары или услуги для продвижения.<br>' +
                    '<br>' +
                    'Партнер, или аффилиат, — тот, кто продвигает товары или услуги рекламодателя.<br>' +
                    '<br>' +
                    'Агент — партнер, который лично рекомендует сервисы рекламодателя своим клиентам, друзьям или знакомым.<br>' +
                    '<br>' +
                    'Лид — потенциальный клиент, который проявил интерес к товару или услуге.<br>' +
                    '<br>' +
                    'Лидогенерация — привлечение потенциальных клиентов. Для этого используются разные маркетинговые инструменты, например контекстная реклама, email-рассылки.<br>' +
                    '<br>' +
                    'Веб-мастер — владелец площадки, который размещает на ней предложения рекламодателя.<br>' +
                    '<br>' +
                    'Арбитраж — покупка трафика и его перепродажа по более высокой цене. Пример: партнер подключается к офферу, вознаграждение за приведенного клиента — 2000 ₽. Он решает продвигать оффер с помощью рекламы на другом сайте и тратит на запуск 20 000 ₽. По ссылкам с рекламы целевое действие совершают 20 человек — значит, стоимость лида составила 1000 ₽. Этот трафик партнер «перепродает» рекламодателю по 2000 ₽ за лида — и получает прибыль.<br>' +
                    '<br>' +
                    'Арбитражник — партнер, который занимается арбитражем трафика.<br>' +
                    '<br>' +
                    'Блогер — партнер, который ведет свой блог и продвигает в нем офферы партнерской программы.<br>' +
                    '<br>' +
                    'Мотивированный трафик — количество клиентов, которых привлекли партнеры с помощью вознаграждения: например, предложили начислить бонусы за установку приложения.<br>' +
                    '<br>' +
                    'Диплинк — ссылка, которая ведет на конкретный раздел сайта или приложения рекламодателя.'
            }
        ]
    }),

    methods: {
        gotoForm() {
            window.scrollTo({
                top: document.getElementById('form-iframe').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },

        gotoUrl(url) {
            document.location.href = url;
        }
    },

    created() {
        this.urlParams = window.location.search;
    },
}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    line-height: 22px;
    color: #333333;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    @media screen and (max-width: 575px) {
        font-size: 15.5px;
    }
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 600;
    font-variant-ligatures: no-contextual;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0;
    text-align: center;
    @media screen and (max-width: 575px) {
        font-size: 24px;
        line-height: 25px;
        font-weight: 400;
    }
}

h3 {
    font-size: 24px;
    line-height: 28px;
    @media screen and (max-width: 575px) {
        font-size: 19px;
        line-height: 24px;
    }
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.splide__pagination {
    bottom: -30px;

    li {
        button {
            background: #DDDFE0;

            &.is-active {
                background: #FFDD2D;
            }
        }
    }
}

.splide__arrow {
    background: #eee;

    svg {
        fill: #336fee;
    }

    &:disabled {
        display: none;
    }

    &.splide__arrow--next {
        right: -3em;
    }

    &.splide__arrow--prev {
        left: -3em;
    }
}

.accordion-list {
    width: 100%;

    .accordion-item {
        --content-height: 0px;
        height: calc(var(--summary-height) + 26px);
        overflow: hidden;
        transition: height 300ms ease-in-out;

        > .accordion-item__summary {
            padding: 12px 0;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: color 300ms ease-in-out;

            &::-webkit-details-marker {
                display: none;
            }

            > .accordion-item__summary-icon {
                transition: transform 300ms ease-in-out;
            }

            > .accordion-item__summary-icon--default::before {
                content: "▼";
                opacity: 30%;
                line-height: 22px;
                font-size: 14px;
            }
        }

    }

    .accordion-item--open {
        height: calc(var(--summary-height) + var(--content-height) + 51px);
        background: var(--acco-lightest);

        > .accordion-item__summary > .accordion-item__summary-icon {
            transform: rotate(180deg);
        }

        p {
            margin-bottom: 15px;
            line-height: 25px;
        }

        li {
            margin-bottom: 10px;
            padding-left: 20px;

            &:before {
                content: '— ';
                margin-left: -20px;
                position: relative;
            }
        }
    }
}

</style>
