<template>
    <button @click="$emit('action')">
        <slot/>
    </button>
</template>

<script>
export default {
    name: "yellow-button-sm",
}
</script>

<style scoped>
@font-face {
    font-family: 'TinkoffSans';
    src: url('../assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

button {
    padding: 8px 10px;
    background-color: #ffdd2d;
    color: #5a5332;
    font-size: 13px;
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 300;
    transition-duration: .5s;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    border-radius: 5px;
}

button:hover {
    background-color: #fab619;
}

</style>