<template>
    <div :style="cssProps">
        <div class="rounded-3xl p-6 text-center text-content flex flex-col items-center h-full sm:flex-row sm:text-left">
            <div v-html="icon" class="rounded-2xl w-[48px] h-[48px] flex icon justify-center items-center text-xl -mt-12 mb-6 sm:-ml-6 sm:mr-6 sm:-mt-6 sm:shrink-0"/>
            <div class="flex-grow-1">
                <slot/>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'step-block',
    props: {
        icon: {},
        iconBackground: {default: '#333333'},
        iconColor: {default: '#ffffff'},
        textBackground: {default: '#ffffff'},
        textColor: {default: '#4c4c4c'},
    },
    data: () => ({}),
    computed: {
        cssProps() {
            return {
                '--text-bg-color': this.textBackground,
                '--text-color': this.textColor,
                '--icon-bg-color': this.iconBackground,
                '--icon-color': this.iconColor,
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.text-content {
    background-color: var(--text-bg-color);
    color: var(--text-color);

    .icon {
        background-color: var(--icon-bg-color);
        color: var(--icon-color);
        font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
        font-weight: 100;
    }
}
</style>