<template>
    <div :style="cssProps">
        <div class="rounded-3xl p-8 round-block sm:rounded-xl h-full w-full flex flex-col">
            <slot/>
        </div>
    </div>
</template>
<script>

export default {
    name: 'round-block',
    props: {
        background: {default: '#ffffff'},
        textColor: {default: '#4c4c4c'},
    },
    data: () => ({}),
    computed: {
        cssProps() {
            return {
                '--text-bg-color': this.background,
                '--text-color': this.textColor,
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.round-block {
    background-color: var(--text-bg-color);
    color: var(--text-color);
}
</style>